import Youtuber from '@/components/ui/card/Youtuber';

import Container from '@/layout/Container';
import { FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';
import { FaTwitch } from 'react-icons/fa6';

export default function Testimonials() {
  return (
    <>
      <Container>
        <div className="flex flex-col gap-9">
          <div className="flex items-center gap-2">
            <div>
              <span className="text-3xl capitalize text-white">
                TRUSTED AND USED BY THE BIGGEST YOUTUBERS
              </span>
            </div>
          </div>

          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="/images/avatar.png"
                  alt="Skooby"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  Skooby
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="http://facebook.com/CSGOSkooby"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaFacebook/>
                </a>
                <a
                  href="http://twitter.com/SkoobyYT"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaTwitter/>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCNsoQ6YQTxZbTqo7Rj4CklQ"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
              </div>
            </div>
            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="https://storage.googleapis.com/seed-storage/1697206187729.jpeg"
                  alt="locco"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  Locco
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="https://twitter.com/loccocsgo"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaTwitter/>
                </a>
                <a
                  href="https://www.youtube.com/c/loccoCSGOHIGHLIGHTS"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
              </div>
            </div>
            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="https://storage.googleapis.com/seed-storage/1697206277744.jpeg"
                  alt="CS ClipZone"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  CS ClipZone
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="https://www.youtube.com/@CS2ClipZone"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
              </div>
            </div>
            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="https://storage.googleapis.com/seed-storage/1703068033240.jpg"
                  alt="Mohzy"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  Mohzy
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="https://www.youtube.com/watch?v=qXx1xWQrJ3Q&ab_channel=mohzy"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
              </div>
            </div>

            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="https://storage.googleapis.com/seed-storage/1704446899562.jpg"
                  alt="Kebus"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  Kebus
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="https://www.youtube.com/watch?v=Pzv2PLdVxU8&ab_channel=Kebus"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
              </div>
            </div>

            <div className="card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10">
              <div className="h-28 w-28 overflow-hidden rounded-full">
                <img
                  className="h-full w-full"
                  src="https://storage.googleapis.com/seed-storage/1704446751834.jpg"
                  alt="ZhenyaLarkin"
                />
              </div>

              <div className="flex flex-col gap-1 text-center">
                <div className="text-2xl capitalize text-slate-100 hover:text-white">
                  ZhenyaLarkin
                </div>
                <div className="font-secondary text-lg text-stone-300">
                  Youtuber
                </div>
              </div>

              <div className="flex gap-4">
                <a
                  href="https://www.youtube.com/watch?v=kz0bA-5DJPA&ab_channel=ZhenyaLarkin"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaYoutube/>
                </a>
                <a
                  href="https://twitch.tv/zhenyalarkin"
                  target="_blank"
                  className="text-secondary-200 hover:text-secondary-100"
                >
                  <FaTwitch/>
                </a>
              </div>
            </div>

            <Youtuber />
            <Youtuber />
          </div>
        </div>
      </Container>
    </>
  );
}
