import Image from 'next/image';

import Container from '@/layout/Container';

import GreenPing from '~/images/green-ping.png';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

export default function RecentlySold() {
  const [loading, setLoading] = useState(true);
  const [soldItems, setItemsSold] = useState([]);

  useEffect(() => {
    const fetchRecentlySold = async () => {
      const response = await fetch('/api/transaction/recently-sold');
      const res = await response.json();
      setLoading(false);
      setItemsSold(res.data);
    };

    fetchRecentlySold();
  }, []);

  if (soldItems.length === 0) {
    return null;
  }

  return (
    <>
      <Container>
        <div className='flex flex-col gap-10'>
          <div className='flex flex-col gap-9'>
            <div className='flex items-center gap-2'>
              <Image className='h-6 w-6' src={GreenPing} alt='Green' />
              <div>
                <span className='text-3xl capitalize text-white'>
                  RECENTLY{' '}
                </span>
                <span className='text-primary-50 text-3xl capitalize'>
                  SOLD
                </span>
                <span className='text-3xl capitalize text-white'> SKINS</span>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6'>
              {soldItems.map((item: any, key) => (
                <div key={key} className={cn(
                  'cursor-pointer card flex h-64 w-full flex-col bg-white bg-opacity-5 p-2.5',
                )}>
                  <div
                    style={{
                      backgroundImage: `url(https://steamcommunity-a.akamaihd.net/economy/image/${item.image})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    className={cn(
                      'flex h-full w-full grow items-center justify-center',
                    )}
                  />
                  <div className='flex  w-full flex-col capitalize text-white'>
                    <div className='text-secondary-200 truncate text-sm'>{item.marketHashName}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
