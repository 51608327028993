import { RootState } from '@/store/index';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string | null;
  name: string | null;
  email: string | null;
  emailVerified: boolean | null;
  image: string | null;
  tradeUrl: string | null;
  referralCode: string | null;
  referralCodeUsed: string | null;
  balance: number;
  totalEarned: number;
  lastSeen: string | null;
  accounts: any[];
  sessions: any[];
  transactions: any[];
  topEarningProducts: any[];
  marketplaceItemTransaction: any[];
  inventories: any[];
  tradeOffers: any[];
  createdAt: string | null;
}

const initialState: {
  user: User;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
} = {
  user: {
    id: null,
    name: null,
    email: null,
    emailVerified: null,
    image: null,
    tradeUrl: null,
    referralCode: null,
    referralCodeUsed: null,
    balance: 0,
    totalEarned: 0,
    lastSeen: null,
    accounts: [],
    sessions: [],
    transactions: [],
    topEarningProducts: [],
    marketplaceItemTransaction: [],
    inventories: [],
    tradeOffers: [],
    createdAt: null,
  },
  status: 'idle',
  error: null,
}

export const fetchUser = createAsyncThunk<User, void>('user/fetchUser', async () => {
  const res = await fetch('/api/find-user');
  const data = await res.json();
  return data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => state = action.payload,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'succeeded';
        Object.assign(state.user, action.payload);
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = 'failed';
      });
  },
});


export const { setUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.userSlice.user;

export default userSlice.reducer; 