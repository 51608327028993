import { FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa';

export default function Youtuber() {
  return (
    <div className='card hover-state bg-surface-200 flex h-[333px] w-full flex-col items-center gap-8 p-10'>
      <div className='h-28 w-28 overflow-hidden rounded-full'>
        <img
          className='h-full w-full'
          src="/images/avatar-un.png"
          alt='Elo'
        />
      </div>

      <div className='flex flex-col gap-1 text-center'>
        <div className='text-2xl capitalize text-slate-100 hover:text-white'>
          Coming Soon
        </div>
        <div className='font-secondary text-lg text-stone-300'>
          Coming Soon
        </div>
      </div>

      <div className='flex gap-4'>
        <a
          href="http://facebook.com/CSGOSkooby"
          target='_blank'
          className='text-secondary-200 hover:text-secondary-100'
        >
          <FaFacebook />
        </a>
        <a
          href="http://twitter.com/SkoobyYT"
          target="_blank"
          className='text-secondary-200 hover:text-secondary-100'
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.youtube.com/channel/UCNsoQ6YQTxZbTqo7Rj4CklQ"
          target="_blank"
          className='text-secondary-200 hover:text-secondary-100'
        >
          <FaYoutube />
        </a>
      </div>
    </div>
  );
}
